import React from "react"
import Layout from "../components/_App/layout"
import Seo from "../components/_App/seo"
import { Link } from "gatsby";

const NotFoundPage = ({ pageContext: { lang, data } }) => (
    <Layout>
        <Seo
            title={data.seoTitle}
            lang={lang}
        />

        <div className="error-area">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="error-content">
                            <div className="notfound-404">
                                <h1>{data.bigText}</h1>
                            </div>
                            <h3>{data.title}</h3>
                            <p>{data.text}</p>

                            <Link to={data.link} className="btn btn-primary">
                                {data.buttonText}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)

export default NotFoundPage
